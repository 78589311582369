<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="paciente.celular == '' || paciente.celular == null"
            >El paciente no tiene registrado un celular en su ficha, puede
            introducir uno a continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="celular"
                prepend-icon="mdi-whatsapp"
                label="Celular"
                :rules="celularRules"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="enviarMensaje"
            >{{ modalTitle }}</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    celular: "",
    celularRules: [(v) => !v || v.length == 10 || "Debe tener 10 caracteres"],
  }),
  props: ["visible", "recetaPedido", "paciente"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    esReceta() {
      return this.recetaPedido.idReceta ? true : false;
    },
    title() {
      return this.esReceta
        ? "Enviar receta electrónica al paciente por whats app"
        : "Enviar pedido electrónico al paciente por whats app";
    },
    text() {
      return this.esReceta
        ? "¿Desea enviar un mensaje de whats app al paciente con la receta electrónica?"
        : "¿Desea enviar un mensaje de whats app al paciente con el pedido electrónico";
    },
    modalTitle() {
      return this.esReceta ? "Enviar Receta" : "Enviar Pedido";
    },
  },

  methods: {
    setearModels() {
      this.celular = this.paciente.celular;
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },
    enviarMensaje() {
      this.$swal({
        title: this.title,
        text: this.text,
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          this.enviarMensajeRecetaPedido();
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    enviarMensajeRecetaPedido() {
      const datos = {
        urlRecetaPedido: this.esReceta
          ? this.recetaPedido.urlReceta
          : this.recetaPedido.urlPedido,
        celular: this.celular,
        idProfesional: this.recetaPedido.idProfesional,
        idPaciente: this.paciente.idPaciente,
        esReceta: this.esReceta,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/EnviarRecetaPedidoWA?", {
          params: {
            urlRecetaPedido: datos.urlRecetaPedido,
            celular: datos.celular,
            idProfesional: datos.idProfesional,
            idPaciente: datos.idPaciente,
            esReceta: datos.esReceta,
          },
        })
        .then((response) => {
          self.showAlert({
            icon: "success",
            title: "Mensaje enviado exitosamente",
            vm: self,
            timer: 4200,
          });
          self.show = false;
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title:
              "El mensaje no pudo ser enviado. Intente mas tarde por favor.",
            vm: this,
            timer: 3000,
          });
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
