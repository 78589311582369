<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nueva Receta</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="dni"
                prepend-icon="mdi-account"
                label="D.N.I Paciente"
                dense
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-combobox
                :items="obrasSociales"
                v-model="obraSocial"
                label="Obras Sociales"
                dense
                prepend-icon="mdi-account"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="nroAfiliado"
                prepend-icon="mdi-account"
                label="Nº de Afiliado"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <v-combobox
                :items="vademecumModificado"
                v-model="medicamento"
                item-text="textoMedicamento"
                label="Medicamento"
                dense
                prepend-icon="mdi-pill"
                hint="Máximo 3 medicamentos por receta"
                persistent-hint
                return-object
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="1">
              <v-text-field
                v-model="cantidad"
                prepend-icon="mdi-account"
                label="Cantidad"
                dense
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="checboxes">
              <v-checkbox
                v-model="genericOnly"
                label="Permite sustición"
                color="primary"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="checboxes">
              <v-checkbox
                v-model="longTerm"
                label="Tratamiento prolongado"
                color="primary"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="add-detail-column">
              <v-btn
                color="warning"
                :disabled="
                  !medicamento ||
                  !medicamento.textoMedicamento ||
                  listaMedicamentos.length > 2 ||
                  cantidad < 1
                "
                @click.stop="agregarMedicamento"
                >Agregar medicamento</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="this.listaMedicamentos"
                class="elevation-1"
                :hide-default-footer="true"
                locale="es-ar"
                item-key="idMedicamento"
                :items-per-page="10000"
                ref="myTable"
                :disable-sort="true"
              >
                <template v-slot:item.genericOnly="{ item }">
                  <v-simple-checkbox
                    v-model="item.genericOnly"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.longTerm="{ item }">
                  <v-simple-checkbox
                    v-model="item.longTerm"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        v-on="on"
                        class="mr-3"
                        color="red"
                        v-bind="attrs"
                        @click="borrarMedicamento(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Eliminar Medicamento</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="diagnostico"
                prepend-icon="mdi-account"
                label="Diagnóstico"
                maxlength="150"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="
              !valid ||
              !listaMedicamentos.length ||
              !obraSocial ||
              !nroAfiliado ||
              !dni
            "
            @click.stop="guardarReceta"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
.add-detail-column {
  display: flex;
  justify-content: end;
}
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import healthInsurances from "../../assets/receta/healthInsurances.json";
import vademecum from "../../assets/receta/vademecum.json";
import newAxios from "axios";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    requiredRules: [(v) => !!v || "Dato obligatorio"],

    prof: "",
    profsBuscar: [],
    fecha: null,
    obraSocial: null,
    dni: null,
    nroAfiliado: null,
    receta: "",
    diagnostico: null,
    medicamento: "",
    cantidad: 1,
    genericOnly: false,
    longTerm: false,
    listaMedicamentos: [],
    headers: [
      { text: "Medicamento", value: "textoMedicamento" },
      { text: "Cantidad", value: "quantity" },
      { text: "Permite sustición", value: "genericOnly" },
      { text: "Tratamiento prolongado", value: "longTerm" },
      { text: "Acciones", value: "actions" },
    ],
  }),
  props: ["visible", "idPaciente", "paciente", "institucion"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    obrasSociales() {
      return healthInsurances.sort((a, b) => a.localeCompare(b));
    },
    vademecumModificado() {
      return vademecum.map((med) => {
        return {
          ...med,
          textoMedicamento: `${med.principioActivo} - ${med.marcaComercial} ${med.presentaciones[0].name}`,
        };
      });
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    agregarMedicamento() {
      this.listaMedicamentos.push({
        medicamento: this.medicamento,
        textoMedicamento: this.medicamento.textoMedicamento,
        quantity: this.cantidad,
        longTerm: this.longTerm,
        genericOnly: this.genericOnly,
      });
      this.medicamento = "";
      this.quantity = 1;
      this.longTerm = false;
      this.genericOnly = false;
    },
    borrarMedicamento(item) {
      this.listaMedicamentos = this.listaMedicamentos.filter(
        (medicamento) => medicamento.textoMedicamento !== item.textoMedicamento
      );
    },
    guardarReceta() {
      this.$swal({
        title: "Registrar Receta",
        text: "¿Desea registrar la receta?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.value) {
          let self = this;
          const datosReceta = {
            fechaReceta: this.fecha,
            idPaciente: this.idPaciente,
            idProfesional: this.prof,
            texto: this.receta,
            diagnostico: this.diagnostico,
            obraSocial: this.obraSocial,
            nroAfiliado: this.nroAfiliado,
          };
          const medicines = this.listaMedicamentos.map((item) => {
            return {
              longTerm: item.longTerm,
              quantity: item.quantity,
              externalId: item.medicamento.presentaciones[0].regNo,
              genericOnly: item.genericOnly,
            };
          });
          const prescripcion = {
            userId: 4461,
            date: this.fecha,
            patient: {
              healthInsurance: self.obraSocial,
              insuranceNumber: self.nroAfiliado,
              name: self.paciente.nombres,
              surname: self.paciente.apellidos,
              documentNumber: self.dni,
            },
            method: "vademecum",
            medicines,
            diagnosis: self.diagnostico,
          };
          let responseExternalAPI;
          try {
            newAxios.defaults.headers.common["Authorization"] =
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzF9.c20qHl2OSKe_gLuDnP9HutwWKGDJpWxp-bM6EdmfGlM";
            responseExternalAPI = await newAxios.post(
              "https://external-api.recetario.com.ar/prescriptions",
              prescripcion
            );
            datosReceta.urlReceta = responseExternalAPI.data.url;
          } catch (error) {
            console.log("error en api", error);
            self.showAlert({
              icon: "error",
              title: "La receta no pudo ser registrada",
              vm: self,
            });
          }
          axios
            .post("/HistoriaClinica/GuardarReceta", datosReceta)
            .then(() => {
              self.showAlert({
                icon: "success",
                title: "Receta registrada exitosamente",
                vm: self,
              });
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              window.open(responseExternalAPI.data.url);
            })
            .catch((error) => {
              console.log("error en nuestra base", error);
              self.showAlert({
                icon: "error",
                title: "La receta no pudo ser registrada",
                vm: this,
              });
            });
        }
      });
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      this.dni = this.paciente.dni;
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;

          self.prof = self.profsBuscar[0].idProfesional;
        });

      axios
        .get("/HistoriaClinica/BuscarUltimaReceta?", {
          params: {
            idPaciente: self.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "") {
            self.nroAfiliado = response.data.nroAfiliado;
          } else {
            self.nroAfiliado = null;
            self.obraSocial = null;
          }
        })
        .catch((error) => {});

      this.fecha = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.receta = null;
      this.obraSocial = null;
      this.nroAfiliado = null;
      this.diagnostico = null;
      this.ultimaReceta = null;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
<style lang="scss">
.checboxes {
  .v-input--checkbox {
    margin-top: 0.6rem;
    padding-top: 0;
    &:first-child {
      margin-left: 1rem;
    }
    .v-input--selection-controls__input {
      margin-right: 2px;
    }
  }
}
</style>
