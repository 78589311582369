<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Receta</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="paciente.mail == '' || paciente.mail == null"
            >El paciente no tiene registrado un mail en su ficha, puede
            introducir uno a continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="enviarMail"
            >Enviar Mail</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import imprimirReceta from "../HistClin/ImprimirEventos/imprimirReceta";
import imprimirRecetaTrinidad from "../HistClin/ImprimirEventos/Trinidad/imprimirReceta";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    base64: null,
    email: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
      (v) => !!v || "Dato obligatorio",
    ],
  }),
  props: ["visible", "receta", "paciente", "institucion"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.getLogoUrl();
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    setearModels() {
      this.email = this.paciente.mail;
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    enviarUrlRecetaPorMail() {
      let self = this;
      this.$swal({
        title: "Enviar Receta",
        text: "¿Desea enviar la receta por email al paciente?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        const datosReceta = {
          urlReceta: this.receta.urlReceta,
          idPaciente: this.paciente.idPaciente,
          mail: this.email,
          idProfesional: this.receta.idProfesional,
        };
        try {
          await axios
            .post("/HistoriaClinica/EnviarRecetaElectronica", datosReceta)
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Receta electrónica enviada exitosamente",
                vm: self,
                timer: 3000,
              });
              self.show = false;
            });
        } catch (error) {
          self.showAlert({
            icon: "error",
            title: "La receta electrónica no pudo ser enviada.",
            vm: this,
            timer: 3000,
          });
        }
      });
    },
    enviarMail() {
      if (this.receta.urlReceta) {
        this.enviarUrlRecetaPorMail();
        return;
      }
      let file = null;
      switch (this.$store.state.usuario.institucion.idInstitucion) {
        case 95: {
          file = imprimirRecetaTrinidad(
            this.institucion,
            this.paciente,
            this.receta,
            this.base64,
            "file"
          );
          break;
        }
        default: {
          file = imprimirReceta(
            this.institucion,
            this.paciente,
            this.receta,
            this.base64,
            "file"
          );
          break;
        }
      }

      this.$swal({
        title: "Enviar Receta",
        text: "¿Desea enviar la receta por email al paciente?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var datosReceta = new FormData();
          datosReceta.append("file", file);
          datosReceta.append("email", this.email);
          datosReceta.append(
            "idInstitucion",
            this.$store.state.usuario.institucion.idInstitucion
          );
          datosReceta.append("idPaciente", this.paciente.idPaciente);
          datosReceta.append("idReceta", this.receta.idReceta);
          let self = this;
          axios({
            method: "post",
            url: "/HistoriaClinica/EnviarReceta",
            data: datosReceta,
            header: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title:
                  "Se envió un mail a la casilla de correo indicada con la receta.",
                vm: self,
                timer: 3000,
              });
              self.show = false;
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La receta no pudo ser enviada.",
                vm: this,
                timer: 3000,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
  },
};
</script>
