<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Pedidos de Estudios</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaPedidos"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="verPedido"
        item-key="idPedido"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.esPedidoElectronico="{ item }">
          <v-simple-checkbox
            v-model="item.esPedidoElectronico"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verPedido(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Pedido</span>
          </v-tooltip>

          <v-tooltip top v-if="!item.urlPedido">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarPedido(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Pedido</span>
          </v-tooltip>

          <v-tooltip top v-if="!item.urlPedido">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarPedido(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Pedido</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarPedido(item)"
                >mdi-email</v-icon
              >
            </template>
            <span>Enviar Pedido de Estudio por Mail</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="green"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarPedidoWA(item)"
                >mdi-whatsapp</v-icon
              >
            </template>
            <span>Enviar Pedido por Whats App</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoPedido">Nuevo Pedido</v-btn>
        <v-btn
          v-if="estaVerTodosDisponible"
          color="warning"
          @click.stop="showVerTodos = true"
          >Ver Todos</v-btn
        >
      </v-card-actions>
      <NuevoPedido
        :visible="showNuevoPedido && !$store.state.usuario.usaRecetaElectronica"
        :idPaciente="idPaciente"
        :paciente="paciente"
        :institucion="institucion"
        @close="showNuevoPedido = false"
        @pedidoRegistrado="pedidoRegistrado"
      ></NuevoPedido>
      <NuevoPedidoElectronico
        :visible="showNuevoPedido && $store.state.usuario.usaRecetaElectronica"
        :idPaciente="idPaciente"
        :paciente="paciente"
        :institucion="institucion"
        @close="pedidoRegistrado"
        @pedidoRegistrado="pedidoRegistrado"
      ></NuevoPedidoElectronico>
      <EditarPedido
        :visible="showEditarPedido"
        :idPaciente="idPaciente"
        :idPedido="editedItem.idPedido"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEditarPedido = false"
        @pedidoModificado="pedidoModificado"
      ></EditarPedido>
      <VerPedido
        :visible="showVerPedido"
        :pedido="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showVerPedido = false"
      ></VerPedido>
      <EnviarPedido
        :visible="showEnviarPedido"
        :pedido="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEnviarPedido = false"
      ></EnviarPedido>
      <VerTodosPedidos
        :visible="showVerTodos"
        :pedidos="listaPedidos"
        :paciente="paciente"
        @close="showVerTodos = false"
      ></VerTodosPedidos>
      <EnviarRecetaPedidoWA
        :visible="showEnviarRecetaPedido"
        :recetaPedido="editedItem"
        :paciente="paciente"
        @close="showEnviarRecetaPedido = false"
      >
      </EnviarRecetaPedidoWA>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoPedido from "../HistClin/NuevoPedido.vue";
import VerPedido from "../HistClin/VerPedido.vue";
import EditarPedido from "../HistClin/EditarPedido.vue";
import EnviarPedido from "../HistClin/EnviarPedido.vue";
import VerTodosPedidos from "../HistClin/VerTodosPedidos.vue";
import NuevoPedidoElectronico from "./NuevoPedidoElectronico.vue";
import EnviarRecetaPedidoWA from "./EnviarRecetaPedidoWA.vue";
export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaPedidoString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Es Pedido Electrónico", value: "esPedidoElectronico" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoPedido: false,
    showVerPedido: false,
    showEditarPedido: false,
    showEnviarPedido: false,
    showEnviarRecetaPedido: false,
    editedItem: {},
    editedIndex: -1,
    showVerTodos: false,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar", "institucion"],
  computed: {
    listaPedidos: {
      set(value) {
        if (value != []) this.$emit("actualizarPedidos");
      },
      get() {
        return this.lista;
      },
    },
    estaVerTodosDisponible() {
      return this.listaPedidos.every((item) => !item.urlPedido);
    },
  },
  methods: {
    borrarPedido(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Pedido",
          text: "¿Desea borrar el pedido?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idPedido = item.idPedido;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarPedido?", {
                params: {
                  idPedido: idPedido,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Pedido borrado exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarPedidos(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El pedido no pudo ser borrado",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El pedido fue creado por un profesional que usted no tiene asociado. No puede eliminar el pedido",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarPedido(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaPedidos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarPedido = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El pedido fue creado por un profesional que usted no tiene asociado. No puede editar el pedido",
          vm: this,
          timer: 3000,
        });
      }
    },
    enviarPedido(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaPedidos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEnviarPedido = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El pedido fue creado por un profesional que usted no tiene asociado. No puede enviar el pedido",
          vm: this,
          timer: 3000,
        });
      }
    },
    enviarPedidoWA(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaPedidos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEnviarRecetaPedido = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El pedido fue creado por un profesional que usted no tiene asociado. No puede enviar el pedido",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevoPedido() {
      this.showNuevoPedido = true;
    },
    verPedido(item) {
      if (item.urlPedido) {
        window.open(item.urlPedido);
      } else {
        this.editedIndex = this.listaPedidos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showVerPedido = true;
      }
    },
    pedidoRegistrado() {
      this.buscarPedidos();
      this.showNuevoPedido = false;
    },
    pedidoModificado() {
      this.buscarPedidos();
      this.showEditarPedido = false;
    },
    buscarPedidos() {
      this.listaPedidos = 1;
    },
  },
  components: {
    NuevoPedido,
    VerPedido,
    EditarPedido,
    VerTodosPedidos,
    EnviarPedido,
    NuevoPedidoElectronico,
    EnviarRecetaPedidoWA,
  },
};
</script>
