<template>
  <div class="container">
    <v-bottom-navigation v-show="alert" fixed>
      <v-btn>
        <v-chip
          class="ma-2"
          close
          color="primary"
          text-color="white"
          @click:close="cancelaCopia"
          >Está copiando o reprogramando un turno</v-chip
        >
      </v-btn>
    </v-bottom-navigation>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Agenda Semanal</v-toolbar-title>
      </v-toolbar>
      <v-row class="ml-2 mr-1 mb-1">
        <!-- <v-toolbar-title>Dr. Mariano Appendino</v-toolbar-title> -->
        <v-col cols="12" md="4" sm="4">
          <v-select
            :items="servicios"
            item-text="nomServicio"
            item-value="servicio"
            menu-props="auto"
            label="Servicio"
            hide-details
            prepend-icon="mdi-account"
            v-model="servicio"
            @change="buscarProfesionales"
            :disabled="isLoading"
            class="mt-6"
            ref="servicio"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-select
            :items="profs"
            item-text="nombreProfesional"
            item-value="idProfesional"
            menu-props="auto"
            label="Profesional"
            hide-details
            prepend-icon="mdi-account"
            v-model="prof"
            :disabled="isLoading"
            class="mt-6"
            ref="vselect"
          ></v-select>
        </v-col>
      </v-row>
      <!-- <v-switch v-model="singleExpand" label="Single expand" class="mt-2"></v-switch> -->
      <v-toolbar flat class="pt-4">
        <v-row>
          <v-btn
            class="ml-4 mr-2"
            max-width="40px"
            min-width="40px"
            @click="retrocederSemana"
            tile
            color="blue"
            dark
          >
            <v-icon large>mdi-arrow-left-bold</v-icon>
          </v-btn>

          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                label="Semana del"
                @blur="date = date"
                readonly
                v-on="on"
                class="shrink"
                style="width: 88px"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              @input="menu2 = false"
              locale="es-ar"
              :first-day-of-week="1"
              @change="armarTurnos"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>

          <v-btn
            class="ml-2"
            max-width="40px"
            min-width="40px"
            @click="avanzarSemana"
            tile
            color="blue"
            dark
          >
            <v-icon large>mdi-arrow-right-bold</v-icon>
          </v-btn>
          <!-- <v-col>
     <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Ver reseña de íconos y colores</v-btn>
      </template>
          </v-col>-->
          <v-spacer></v-spacer>
          <div v-if="isLoading" class="loader mr-2"></div>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar>

      <v-card-title>
        <v-row class="mt-0 pt-0 mb-0 pb-0">
          <v-col cols="12" class="mt-0 pt-0 mb-0 pb-0">
            <v-checkbox
              v-model="refrescarPantalla"
              label="Refresco Automático de Pantalla"
              color="primary"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0 mb-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar turnos en la semana"
              single-line
              hide-details
              dense=""
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="this.listaTurnos"
        :search="search"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :hide-default-footer="true"
        :loading="isLoading"
        item-key="id"
        locale="es-ar"
        show-expand
        class="elevation-1 mytable"
        :disable-sort="true"
        :items-per-page="500"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            v-show="
              item.paciente != 'Disponible' &&
              item.hora != 'Feriado' &&
              item.observacionesTurno != 'Cancelación'
            "
            :colspan="headers.length"
            style="background-color: #e3e3e3"
          >
            <strong
              >{{
                $store.state.usuario.institucion.esEmpresa
                  ? "Sede"
                  : "Obra Social"
              }}:</strong
            >
            {{ item.obraSocial }}
            <br />
            <strong>Direccion:</strong>
            {{ item.direccion }}
            <br />
            <strong>Mail:</strong>
            {{ item.mail }}
            <br />
            <strong>Telefono:</strong>
            {{ item.telefono }}
            <br />
            <strong>Celular:</strong>
            {{ item.celular }}
            <br />
            <strong>Observaciones del Paciente:</strong>
            {{ item.observacionesPaciente }}
            <br />
            <strong>Observaciones del Turno:</strong>
            {{ item.observacionesTurno }}
          </td>
        </template>

        <template v-slot:item.diaSemana="{ item }">
          <v-chip
            :color="
              getColor(
                item.hora,
                item.paciente,
                item.entreTurno,
                item.estadoTurno,
                item.observacionesTurno,
                item.idObraSocial
              )
            "
            dark
          >
            <v-avatar class="mr-2" color="#541402" size="12">
              <span class="white--text">{{
                item.diaSemana.toString().substring(0, 2)
              }}</span>
            </v-avatar>
            {{ item.fechaTurnoString }}</v-chip
          >
        </template>

        <template v-slot:item.hora="{ item }">
          <v-chip
            :color="
              getColor(
                item.hora,
                item.paciente,
                item.entreTurno,
                item.estadoTurno,
                item.observacionesTurno,
                item.idObraSocial
              )
            "
            dark
            >{{ item.hora }}</v-chip
          >
        </template>

        <template v-slot:item.paciente="{ item }">
          <v-chip
            :color="
              getColorPaciente(
                item.hora,
                item.paciente,
                item.entreTurno,
                item.estadoTurno,
                item.observacionesTurno,
                item.idObraSocial
              )
            "
            dark
          >
            {{ item.paciente }}
            <v-avatar
              class="ml-2"
              v-if="item.cantIntervalos > 1"
              color="#541402"
              size="32"
            >
              <span class="white--text">{{ item.cantIntervalos }}</span>
            </v-avatar>
          </v-chip>
        </template>
        <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
        </template>-->
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="
                  item.paciente == 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.hora != 'Cancelación'
                "
                small
                rounded
                color="green"
                dark
                @click.stop="nuevoTurno(item, 0)"
              >
                <v-icon class="mr-2" dark left> mdi-calendar </v-icon>Nuevo
                Turno
              </v-btn>
            </template>
            <span>Nuevo Turno</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1
                "
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarTurno(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Turno</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1 &&
                  ($store.state.usuario.institucion.idInstitucion !== 195 ||
                    $store.state.usuario.roles.some((o) => o.idRol === 1))
                "
                v-on="on"
                color="red"
                v-bind="attrs"
                @click.stop="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Turno</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1
                "
                v-on="on"
                color="grey"
                v-bind="attrs"
                @click.stop="nuevoTurno(item, 1)"
                >mdi-calendar-multiple</v-icon
              >
            </template>
            <span>Entre Turno</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="blue"
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1 &&
                  (roles.some((o) => o.idRol === 1) ||
                    roles.some((o) => o.idRol === 4))
                "
                v-on="on"
                v-bind="attrs"
                @click.stop="historiaClinica(item)"
                >mdi-heart-pulse</v-icon
              >
            </template>
            <span>Historia Clínica</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="#d35400"
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1 &&
                  (roles.some((o) => o.idRol === 1) ||
                    roles.some((o) => o.idRol === 5))
                "
                v-on="on"
                v-bind="attrs"
                @click.stop="cobrar(item)"
                >mdi-currency-usd</v-icon
              >
            </template>
            <span>Cobrar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1
                "
                medium
                color="black"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click="copiarReprogramar(item)"
                >mdi-content-duplicate</v-icon
              >
            </template>
            <span>Copiar / Reprogramar Turno</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="
                  item.esVideoLlamada == false &&
                  item.paciente != 'Disponible' &&
                  item.hora != 'Feriado' &&
                  item.observacionesTurno != 'Cancelación' &&
                  copiar != 1 &&
                  utilizaLlamador == true &&
                  (($store.state.usuario.institucion.idInstitucion === 195 &&
                    (item.estadoTurno === 2 || item.estadoTurno === 3)) ||
                    ($store.state.usuario.institucion.idInstitucion !== 195 &&
                      item.estadoTurno == '2')) &&
                  (roles.some((o) => o.idRol === 1) ||
                    roles.some((o) => o.idRol === 4)) &&
                  esDeHoy(item)
                "
                medium
                color="indigo"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click="llamarPaciente(item)"
                >mdi-bullhorn</v-icon
              >
            </template>
            <span>Llamar Paciente</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="
                  item.esVideoLlamada == true &&
                  (roles.some((o) => o.idRol === 1) ||
                    roles.some((o) => o.idRol === 4)) &&
                  esDeHoy(item)
                "
                medium
                color="indigo"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click="iniciarVideoConsulta(item)"
                >mdi-video</v-icon
              >
            </template>
            <span>Iniciar Videoconsulta</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="chequearEnviarDatosTurno(item)"
                medium
                color="green"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click="enviarDatosTurno(item)"
                >mdi-whatsapp</v-icon
              >
            </template>
            <span>Enviar Datos Turno</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-alert
        color="blue"
        dark
        outlined
        v-if="listaTurnos.length == 0 && !isLoading"
        class="mb-0 mt-2"
        >No hay agendas configuradas para esta semana.</v-alert
      >
      <v-card-title>
        <v-btn
          color="success"
          @click="mostrarCronogramas"
          dark
          class="mb-2"
          v-on="on"
          >Ver Cronogramas Vigentes</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="mostrarColores"
          dark
          class="mb-2"
          v-on="on"
          >Ver reseña de íconos y colores</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="error" @click="salir" dark class="mb-2" v-on="on"
          >Salir</v-btn
        >
      </v-card-title>
      <NuevoTurno
        :visible="showNuevoTurno"
        :dia="editedItem.fechaTurnoString"
        :hora="horaTurno"
        :idProfesional="prof"
        :tiempoTurno="editedItem.tiempoTurno"
        :entreTurno="this.entreTurno"
        :tipoTurno="editedItem.tipoTurno"
        @close="showNuevoTurno = false"
        @turnoRegistrado="turnoRegistrado"
      ></NuevoTurno>
      <EditarTurno
        :visible="showEditarTurno"
        :dia="editedItem.fechaTurnoString"
        :hora="editedItem.hora"
        :idProfesional="prof"
        :tiempoTurno="editedItem.tiempoTurno"
        :idTurno="editedItem.idTurno"
        :observacionesPaciente="editedItem.observacionesPaciente"
        @close="showEditarTurno = false"
        @turnoModificado="turnoModificado"
      ></EditarTurno>
      <Colores :visible="showColores" @close="showColores = false"></Colores>
      <VerCronogramas
        :visible="showCronogramas"
        :prof="prof"
        @close="showCronogramas = false"
      ></VerCronogramas>
      <NuevoCobro
        :visible="showNuevoCobro"
        :idTurno="editedItem.idTurno"
        :estadoTurno="editedItem.estadoTurno"
        :pacienteTurno="editedItem.paciente"
        :idProfesional="prof"
        :idPacienteTurno="editedItem.idPaciente"
        @recargarLista="armarTurnos"
        @close="cierraNuevoCobro()"
      ></NuevoCobro>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import EditarTurno from "../Turnos/EditarTurno.vue";
import NuevoTurno from "../Turnos/NuevoTurno.vue";
import Colores from "../Turnos/Colores.vue";
import VerCronogramas from "../Turnos/VerCronogramasActivos.vue";
import NuevoCobro from "../Caja/NuevoCobro.vue";
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
export default {
  name: "GestionTurnos",

  data: (vm) => ({
    dialog: false,
    alert: false,
    utilizaLlamador: false,
    roles: [],
    showNuevoTurno: false,
    showEditarTurno: false,
    showColores: false,
    showCronogramas: false,
    showNuevoCobro: false,
    valid: true,
    copiar: 0,
    name: "",
    refrescarPantalla: null,
    interval: null,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    isLoading: false,
    expanded: [],
    singleExpand: false,
    search: "",
    headers: [
      {
        text: "Fecha",
        align: "start",
        filterable: false,
        value: "diaSemana",
      },
      {
        text: "Hora",
        align: "start",
        filterable: false,
        value: "hora",
      },
      { text: "Paciente", value: "paciente" },
      // {text: "Turnos Consecutivos", align:"center",value:"intervalos"},
      { text: "Acciones", value: "actions", sortable: false },
    ],
    headersLength: {
      type: Number,
    },
    editedIndex: -1,
    editedItem: {
      hora: "",
      paciente: "",
    },
    defaultItem: {
      hora: "",
      paciente: "",
    },
    //date: new Date().toISOString().substr(0, 10),
    //dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: null,
    dateFormatted: null,
    menu: false,
    modal: false,
    menu2: false,
    prof: "",
    profs: [],
    servicio: null,
    servicios: [],
    diaSemana: null,
    on: null,
    horaTurno: "",
    entreTurno: "",
    listaTurnos: [],
    itemCopia: {
      idTurno: null,
      tiempoTurno: null,
      cantIntervalos: null,
      fechaTurnoString: null,
    },
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    refrescarPantalla() {
      this.refrescar();
    },
    prof: {
      handler(val) {
        if (val) {
          this.armarTurnos();
        }
      },
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Turno" : "Editar Turno";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    var hoy = new Date().getDay();
    if (hoy == 0) hoy = 6;
    else hoy -= 1;
    let dias = 1000 * 60 * 60 * 24 * hoy;

    let resta = new Date().getTime() - dias;
    this.date = this.toISOLocal(new Date(resta));
    this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));

    this.servicios = [...this.$store.state.usuario.profesionales];
    this.servicios.unshift({
      nomServicio: "TODOS",
      servicio: -1,
    });
    this.servicio = this.servicios[0].servicio;

    this.profs = this.$store.state.usuario.profesionales;

    if (this.$store.state.indexProf != 0)
      this.prof = this.$store.state.indexProf;
    else this.prof = this.profs[0].idProfesional;

    this.armarTurnos();
    this.utilizaLlamador =
      this.$store.state.usuario.institucion.utilizaLlamador;
    this.roles = this.$store.state.usuario.roles;
  },
  mounted() {
    this.refrescarPantalla =
      this.$store.state.usuario.institucion.idInstitucion === 195
        ? true
        : false;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    buscarProfesionales() {
      if (this.servicio === -1) {
        this.profs = [...this.$store.state.usuario.profesionales];
      } else {
        this.profs = this.$store.state.usuario.profesionales.filter(
          (prof) => prof.servicio === this.servicio
        );
      }
      this.prof = this.profs[0].idProfesional;
    },
    enviarDatosTurno(item) {
      const datosTurno = {
        idTurno: item.idTurno,
        fechaTurno: item.fechaTurnoString,
        horaTurno: item.hora,
      };
      let self = this;
      axios
        .get("/Turnos/EnviarDatosTurno", {
          params: {
            idTurno: datosTurno.idTurno,
            fechaTurno: datosTurno.fechaTurno,
            horaTurno: datosTurno.horaTurno,
          },
        })
        .then((response) => {
          self.showAlert({
            icon: "success",
            title: "Datos enviados exitosamente",
            vm: self,
          });
        })
        .catch((error) => {});
    },
    chequearEnviarDatosTurno(item) {
      if (
        (this.$store.state.usuario.institucion.idInstitucion === 114 ||
          this.$store.state.usuario.institucion.idInstitucion === 270) &&
        item.celular
      ) {
        return true;
      }
      return item.celular && item.celular.toString().length == 10;
    },
    salir() {
      router.push("/Home");
    },
    llamarPaciente(item) {
      const datosLlamada = {
        nombreProfesional:
          this.$refs.vselect.selectedItems[0].nombreProfesional,
        idProfesional: this.prof,
        nombrePaciente: item.paciente,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        fechaTurno: item.fechaTurnoString,
        horaTurno: item.hora.toString().substr(0, 5),
      };
      let self = this;
      axios
        .get("/Turnos/LlamarPaciente?", {
          params: {
            nombreProfesional: datosLlamada.nombreProfesional,
            idProfesional: datosLlamada.idProfesional,
            nombrePaciente: datosLlamada.nombrePaciente,
            idInstitucion: datosLlamada.idInstitucion,
            fechaTurno: datosLlamada.fechaTurno,
            horaTurno: datosLlamada.horaTurno,
          },
        })
        .then((response) => {
          if (response.data == "Ok") {
            self.showAlert({
              icon: "success",
              title:
                "El paciente " + item.paciente + " fue llamado correctamente",
              vm: this,
            });
          }
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El paciente no pudo ser llamado",
            vm: this,
          });
        });
    },
    esDeHoy(item) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        this.toISOLocal(new Date()).substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);

      if (desde.toString() == hoy.toString()) {
        return true;
      } else {
        return false;
      }
    },
    historiaClinica(item) {
      this.$store.dispatch("setDni", item.dni);
      this.$store.dispatch("setRutaSalidaHC", 2);
      this.$store.dispatch("setIndexProf", this.prof);
      if (
        ((this.$store.state.usuario.institucion.idInstitucion !== 195 &&
          item.estadoTurno != 3) ||
          item.estadoTurno == 2) &&
        !this.$store.state.usuario.institucion.esEmpresa
      ) {
        this.$swal({
          title: "Marcar como Atendido",
          text: "¿Desea marcar el turno como atendido?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value == true) {
            axios.get("/Turnos/Atendido?", {
              params: {
                idTurno: item.idTurno,
              },
            });
          }
        });
      }
      router.push("/HistoriasClinicas");
    },
    iniciarVideoConsulta(item) {
      this.$store.dispatch("setVideollamada", true);
      this.$store.dispatch("setLinkVideollamada", item.linkVideoLlamada);
      this.$store.dispatch(
        "setNombreProfesional",
        this.$refs.vselect.selectedItems[0].nombreProfesional
      );
      this.$store.dispatch("setNombrePaciente", item.paciente);
      this.$store.dispatch("setDni", item.dni);
      this.$store.dispatch("setRutaSalidaHC", 2);
      this.$store.dispatch("setIndexProf", this.prof);
      if (
        ((this.$store.state.usuario.institucion.idInstitucion !== 195 &&
          item.estadoTurno != 3) ||
          item.estadoTurno == 2) &&
        !this.$store.state.usuario.institucion.esEmpresa
      ) {
        this.$swal({
          title: "Marcar como Atendido",
          text: "¿Desea marcar el turno como atendido?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value == true) {
            axios.get("/Turnos/Atendido?", {
              params: {
                idTurno: item.idTurno,
              },
            });
          }
        });
      }
      if (this.isMobile() == false) router.push("/HistoriasClinicas");
      else router.push("/VideoLlamada");
    },
    retrocederSemana() {
      let dias = 1000 * 60 * 60 * 24 * 6;
      let resta = new Date(this.date).getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));
      this.armarTurnos();
    },
    avanzarSemana() {
      let dias = 1000 * 60 * 60 * 24 * 8;
      let resta = new Date(this.date).getTime() + dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));
      this.armarTurnos();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    cobrar(item) {
      this.editedIndex = this.listaTurnos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showNuevoCobro = true;
    },
    cierraNuevoCobro() {
      this.showNuevoCobro = false;
    },
    allowedDates: (val) => new Date(val).getDay() === 0,
    refrescar() {
      if (this.refrescarPantalla === true) {
        this.interval = setInterval(
          () => this.armarTurnos(),
          this.$store.state.usuario.institucion.tiempoRefresco * 1000
        );
      } else {
        clearInterval(this.interval);
      }
    },
    copiarReprogramar(item) {
      this.copiar = 1;
      this.alert = !this.alert;
      this.itemCopia.tiempoTurno = item.tiempoTurno;
      this.itemCopia.fechaTurnoString = item.fechaTurnoString;
      this.itemCopia.cantIntervalos = item.cantIntervalos;
      this.itemCopia.idTurno = item.idTurno;
    },
    cancelaCopia() {
      this.alert = !this.alert;
      this.copiar = 0;
      this.itemCopia.tiempoTurno = null;
      this.itemCopia.fechaTurnoString = null;
      this.itemCopia.cantIntervalos = null;
      this.itemCopia.idTurno = null;
    },
    armarTurnos() {
      this.isLoading = true;
      this.$loading(true);
      const turnosData = {
        idProfesional: this.prof,
        fecha: this.date,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      //this.listaTurnos = []
      let self = this;
      axios
        .get("/Turnos/ArmarTurnosSemana?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
          },
        })
        .then(function (response) {
          self.listaTurnos = response.data;
          self.isLoading = false;
          self.$loading(false);
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$loading(false);
        });

      // this.$store.dispatch("armarTurnos", {
      //   idProfesional: turnosData.idProfesional,
      //   fecha: turnosData.fecha
      // });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getColor(
      hora,
      paciente,
      entreTurno,
      estadoTurno,
      observacionesTurno,
      idObraSocial
    ) {
      if (hora === "Feriado" || observacionesTurno === "Cancelación")
        return "red";
      else {
        if (paciente === "Disponible") return "green";
        else {
          if (estadoTurno === 1) {
            if (idObraSocial == 162 || idObraSocial == 91) return "black";
            else {
              if (entreTurno === 1) return "grey";
              else return "blue";
            }
          } else {
            if (estadoTurno === 2) return "orange";
            else return "purple";
          }
        }
      }
    },
    getColorFecha(diaSemana) {
      if (diaSemana.toString().substring(0, 2) == "LU") return "#aeaeae";
      if (diaSemana.toString().substring(0, 2) == "MA") return "#868686";
      if (diaSemana.toString().substring(0, 2) == "MI") return "#626262";
      if (diaSemana.toString().substring(0, 2) == "JU") return "#454545";
      if (diaSemana.toString().substring(0, 2) == "VI") return "#333333";
      if (diaSemana.toString().substring(0, 2) == "SA") return "#1f1f1f";
      if (diaSemana.toString().substring(0, 2) == "DO") return "#000000";
    },
    getColorPaciente(
      hora,
      paciente,
      entreTurno,
      estadoTurno,
      observacionesTurno,
      idObraSocial
    ) {
      if (hora === "Feriado" || observacionesTurno === "Cancelación")
        return "red";
      else {
        if (paciente === "Disponible") return "green";
        else {
          if (estadoTurno === 1) {
            if (idObraSocial == 162 || idObraSocial == 91) return "black";
            else {
              if (entreTurno === 1) return "grey";
              else return "blue";
            }
          } else {
            if (estadoTurno === 2) return "orange";
            else return "purple";
          }
        }
      }
    },
    editarTurno(item) {
      this.editedIndex = this.listaTurnos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarTurno = true;
    },
    nuevoTurno(item, val) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        this.toISOLocal(new Date()).substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);
      if (desde < hoy) {
        this.$swal({
          title: "Fecha menor al día de hoy",
          text: "Cuidado, la fecha del turno es menor a la fecha actual. ¿Desea continuar igual?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.dismiss == "cancel") {
            return;
          } else {
            this.continuarNuevoTurno(item, val);
          }
        });
      } else {
        this.continuarNuevoTurno(item, val);
      }
    },
    continuarNuevoTurno(item, val) {
      if (this.copiar == 0) {
        this.editedIndex = this.listaTurnos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        if (val === 0) {
          this.horaTurno = this.editedItem.hora;
          this.entreTurno = 0;
          this.showNuevoTurno = true;
        } else {
          let self = this;
          axios
            .get("/Turnos/ChequearCantMaxEt?", {
              params: {
                fecha: self.editedItem.fechaTurnoString,
                idProfesional: self.prof,
              },
            })
            .then((response) => {
              if (response.data == "ok") {
                this.horaTurno = this.calcularHora(this.editedItem.hora);
                this.entreTurno = 1;
                this.showNuevoTurno = true;
              } else {
                self.showAlert({
                  icon: "error",
                  title:
                    "Supera la cantidad máxima de entre turnos diarios establecida para el profesional",
                  vm: self,
                });
              }
            })
            .catch((error) => {});
        }
      } else {
        let self = this;
        this.$swal({
          title: "Copiar o Reprogramar",
          text: "Elija una opción. Si desea salir apriete la X",
          icon: "success",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "orange",
          cancelButtonColor: "purple",
          confirmButtonText: "Copiar",
          cancelButtonText: "Reprogramar",
        }).then((result) => {
          if (result.value) {
            const datosTurno = {
              idTurno: this.itemCopia.idTurno,
              idProfesional: this.prof,
              horaTurno: item.hora,
              diaTurno: item.fechaTurnoString,
              cantIntervalos: this.itemCopia.cantIntervalos,
              tiempoTurno: this.itemCopia.tiempoTurno,
              usuarioAlta: this.$store.state.usuario.usuario,
            };
            axios
              .get("/Turnos/CopiarTurno", {
                params: {
                  idTurno: datosTurno.idTurno,
                  idProfesional: datosTurno.idProfesional,
                  horaTurno: datosTurno.horaTurno,
                  fechaTurno: datosTurno.diaTurno,
                  cantIntervalos: datosTurno.cantIntervalos,
                  tiempoTurno: datosTurno.tiempoTurno,
                  usuarioAlta: datosTurno.usuarioAlta,
                },
              })
              .then((response) => {
                if (response.data == "Turnos Existentes") {
                  self.showAlert({
                    icon: "error",
                    title:
                      "Existen turnos ya reservados dentro de la duración del turno que quiere reservar",
                    vm: self,
                  });
                } else {
                  self.showAlert({
                    icon: "success",
                    title: "Turno copiado exitosamente",
                    vm: self,
                  });
                  self.armarTurnos();
                  self.cancelaCopia();
                }
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El turno no pudo ser copiado",
                  vm: self,
                });
              });
          } else {
            if (result.dismiss == "cancel") {
              const datosTurno = {
                idTurno: this.itemCopia.idTurno,
                idProfesional: this.prof,
                horaTurno: item.hora,
                diaTurno: item.fechaTurnoString,
                cantIntervalos: this.itemCopia.cantIntervalos,
                tiempoTurno: this.itemCopia.tiempoTurno,
                usuarioModi: this.$store.state.usuario.usuario,
              };
              axios
                .get("/Turnos/ReprogramarTurno", {
                  params: {
                    idTurno: datosTurno.idTurno,
                    idProfesional: datosTurno.idProfesional,
                    horaTurno: datosTurno.horaTurno,
                    fechaTurno: datosTurno.diaTurno,
                    cantIntervalos: datosTurno.cantIntervalos,
                    tiempoTurno: datosTurno.tiempoTurno,
                    usuarioModi: datosTurno.usuarioModi,
                  },
                })
                .then((response) => {
                  if (response.data == "Turnos Existentes") {
                    self.showAlert({
                      icon: "error",
                      title:
                        "Existen turnos ya reservados dentro de la duración del turno que quiere reservar",
                      vm: self,
                    });
                  } else {
                    self.showAlert({
                      icon: "success",
                      title: "Turno reprogramado exitosamente",
                      vm: self,
                    });
                    self.armarTurnos();
                    self.cancelaCopia();
                  }
                })
                .catch((error) => {
                  self.showAlert({
                    icon: "error",
                    title: "El turno no pudo ser reprogramado",
                    vm: self,
                  });
                });
            }
          }
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    isMobile() {
      if (window.innerWidth < 992) {
        return true;
      } else {
        return false;
      }
    },
    mostrarColores() {
      this.showColores = true;
    },
    mostrarCronogramas() {
      this.showCronogramas = true;
    },
    calcularHora(hora) {
      var division = hora.split(":");
      var hoy = new Date();
      var fecha = new Date(
        hoy.getYear(),
        hoy.getMonth(),
        hoy.getDay(),
        division[0],
        parseInt(division[1] - 1)
      );
      var minutos = fecha.getMinutes().toString();
      if (minutos.length == 1) minutos = "0" + minutos;
      var horaNueva = fecha.getHours() + ":" + minutos;
      return horaNueva;
    },
    deleteItem(item) {
      this.$swal({
        title: "Borrar Turno",
        text: "¿Desea borrar el turno?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTurno = item.idTurno;
          let self = this;
          axios
            .get("/Turnos/BorrarTurno?", {
              params: {
                idTurno: idTurno,
                usuario: this.$store.state.usuario.usuario,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Turno borrado",
                vm: self,
              });
              setTimeout(self.armarTurnos(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El turno no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    turnoRegistrado() {
      this.armarTurnos();
      setTimeout(this.cerrarVentana, 600);
    },
    cerrarVentana() {
      this.showNuevoTurno = false;
    },
    turnoModificado() {
      this.armarTurnos();
      setTimeout(this.cerrarVentanaModificar, 600);
    },
    cerrarVentanaModificar() {
      this.showEditarTurno = false;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
  },
  components: {
    EditarTurno,
    NuevoTurno,
    Colores,
    VerCronogramas,
    NuevoCobro,
  },
};
</script>
