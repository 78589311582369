import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import * as VueGoogleMaps from "vue2-google-maps";
import VueViewer from "v-viewer";
import { mapActions } from "vuex";
import "viewerjs/dist/viewer.css";
import VueLoading from "vuejs-loading-plugin";
Vue.use(Vuelidate);
Vue.use(VueViewer);
VueViewer.setDefaults({
  zIndexInline: 2017,
});
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDTvuisfH7eyVRBBYrZYTdaGhlOVb4LP9k",
    libraries: "places", // This is required if you use the Autocomplete plugin
    region: "AR",
    languaje: "AR",
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

Vue.use(VueLoading, {
  classes: ["page-loader"],
  text: "Por favor espere",
});

Vue.prototype.$appVersion = "29.0.1";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),

  created() {
    this.setAuth();
  },
  methods: {
    ...mapActions(["setAuth"]),
  },
}).$mount("#app");
