<template>
  <div class="ma-0 pa-0">
    <v-bottom-navigation v-show="alert" fixed>
      <v-btn>
        <v-chip
          class="ma-2"
          close
          color="primary"
          text-color="white"
          @click:close="cancelaCopia"
          >Está copiando o reprogramando un turno</v-chip
        >
      </v-btn>
    </v-bottom-navigation>
    <v-card tile>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Agenda Semanal</v-toolbar-title>
      </v-toolbar>
      <v-row class="ml-2 mr-1 mb-1">
        <!-- <v-toolbar-title>Dr. Mariano Appendino</v-toolbar-title> -->
        <v-col cols="12" md="4" sm="4">
          <v-select
            :items="servicios"
            item-text="nomServicio"
            item-value="servicio"
            menu-props="auto"
            label="Servicio"
            hide-details
            prepend-icon="mdi-account"
            v-model="servicio"
            @change="buscarProfesionales"
            :disabled="isLoading"
            class="mt-6"
            ref="servicio"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-select
            :items="profs"
            item-text="nombreProfesional"
            item-value="idProfesional"
            menu-props="auto"
            label="Profesional"
            hide-details
            prepend-icon="mdi-account"
            v-model="prof"
            :disabled="isLoading"
            class="mt-6"
            ref="vselect"
          ></v-select>
        </v-col>
      </v-row>
      <v-toolbar flat class="pt-4">
        <v-row>
          <v-btn
            class="ml-4 mr-2"
            max-width="40px"
            min-width="40px"
            @click="retrocederSemana"
            tile
            color="blue"
            dark
          >
            <v-icon large>mdi-arrow-left-bold</v-icon>
          </v-btn>

          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                label="Semana del"
                @blur="date = date"
                readonly
                v-on="on"
                class="shrink"
                style="width: 88px"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              @input="menu2 = false"
              locale="es-ar"
              :first-day-of-week="1"
              @change="armarTurnos"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>

          <v-btn
            max-width="40px"
            min-width="40px"
            class="ml-2 mr-2"
            @click="avanzarSemana"
            tile
            color="blue"
            dark
          >
            <v-icon large>mdi-arrow-right-bold</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div v-if="isLoading" class="loader mr-2"></div>
          <h4 v-if="isLoading" class="mt-2">Cargando turnos</h4>
          <v-spacer></v-spacer>
          <v-btn
            class="ml-2 mr-2"
            @click="buscarPrimerTurno"
            tile
            color="success"
            dark
          >
            Ir a semana con primer turno disponible
          </v-btn>
        </v-row>
      </v-toolbar>

      <v-card-title>
        <v-row class="mt-0 pt-0 mb-0 pb-0">
          <v-col cols="12" class="mt-0 pt-0 mb-0 pb-0">
            <v-checkbox
              v-model="refrescarPantalla"
              label="Refresco Automático de Pantalla"
              color="primary"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-title>
      <NuevoTurno
        :visible="showNuevoTurno"
        :dia="editedItem.fechaTurnoString"
        :hora="horaTurno"
        :idProfesional="prof"
        :tiempoTurno="editedItem.tiempoTurno"
        :entreTurno="this.entreTurno"
        :tipoTurno="editedItem.tipoTurno"
        @close="showNuevoTurno = false"
        @turnoRegistrado="turnoRegistrado"
      ></NuevoTurno>
      <EditarTurno
        :visible="showEditarTurno"
        :dia="editedItem.fechaTurnoString"
        :hora="editedItem.hora.substring(0, 5)"
        :idProfesional="prof"
        :tiempoTurno="editedItem.tiempoTurno"
        :idTurno="editedItem.idTurno"
        :observacionesPaciente="editedItem.observacionesPaciente"
        @close="showEditarTurno = false"
        @turnoModificado="turnoModificado"
      ></EditarTurno>
      <Colores :visible="showColores" @close="showColores = false"></Colores>
      <VerCronogramas
        :visible="showCronogramas"
        :prof="prof"
        @close="showCronogramas = false"
      ></VerCronogramas>
      <NuevoCobro
        :visible="showNuevoCobro"
        :idTurno="editedItem.idTurno"
        :estadoTurno="editedItem.estadoTurno"
        :pacienteTurno="editedItem.paciente"
        :idProfesional="prof"
        :idPacienteTurno="editedItem.idPaciente"
        @recargarLista="armarTurnos"
        @close="cierraNuevoCobro()"
      ></NuevoCobro>
      <HoraFinExcepcion
        :visible="showHoraFinExcepcion"
        :horaItem="horaItem"
        @close="showHoraFinExcepcion = false"
        @actualizarHoraHasta="actualizarHoraHasta($event)"
      ></HoraFinExcepcion>
    </v-card>
    <v-row>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerLunes"
          :items="this.listaTurnosLunes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>

        <v-menu
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list dense>
            <v-list-item
              v-for="(item, index) in itemsMenu"
              :key="index"
              @click="definirAccion(item)"
            >
              <v-list-item-icon class="mr-2 pr-2">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerMartes"
          :items="this.listaTurnosMartes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerMiercoles"
          :items="this.listaTurnosMiercoles"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerJueves"
          :items="this.listaTurnosJueves"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerViernes"
          :items="this.listaTurnosViernes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerSabado"
          :items="this.listaTurnosSabado"
          dense
          class="row-pointer"
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerDomingo"
          :items="this.listaTurnosDomingo"
          dense
          v-if="listaTurnosDomingo.length > 0"
          class="row-pointer"
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
          <template v-slot:item.hora="{ item }">
            <v-tooltip
              top
              v-if="item.observacionesTurno || item.observacionesPaciente"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">{{ item.hora }}</span>
              </template>
              <span v-if="item.observacionesTurno"
                >Observaciones del Turno: {{ item.observacionesTurno }}</span
              >
              <br v-if="item.observacionesTurno" />
              <span v-if="item.observacionesPaciente"
                >Observaciones del Paciente:
                {{ item.observacionesPaciente }}</span
              >
            </v-tooltip>
            <div v-else>
              <span>{{ item.hora }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-alert
      color="blue"
      dark
      outlined
      v-if="
        listaTurnosLunes.length == 0 &&
        listaTurnosMartes.length == 0 &&
        listaTurnosMiercoles.length == 0 &&
        listaTurnosJueves.length == 0 &&
        listaTurnosViernes.length == 0 &&
        listaTurnosSabado.length == 0 &&
        listaTurnosDomingo.length == 0 &&
        !isLoading
      "
      class="mb-0 mt-2"
      >No hay agendas para configuradas esta semana.</v-alert
    >
    <v-card-title>
      <v-btn
        color="#005d04"
        @click="mostrarCronogramas"
        dark
        class="mb-2"
        v-on="on"
        >Ver Cronogramas Vigentes</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="mostrarColores" dark class="mb-2" v-on="on"
        >Ver reseña de íconos y colores</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="error" @click="salir" dark class="mb-2" v-on="on"
        >Salir</v-btn
      >
    </v-card-title>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
::v-deep .v-data-table__wrapper {
  color: white;
  font-weight: bold;

  height: fit-content;
}
::v-deep .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 38px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.dos > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.dos > td {
  height: 76px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.tres > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.tres > td {
  height: 114px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.cuatro > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.cuatro > td {
  height: 152px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.cinco > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.cinco > td {
  height: 190px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.seis > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.seis > td {
  height: 228px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.siete > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.siete > td {
  height: 266px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.ocho > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.ocho > td {
  height: 304px;
  font-size: 0.67rem;
  border: 0.5px solid;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: rgb(0, 0, 0);
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.row-pointer tbody tr :hover {
  cursor: pointer;
}
td {
  border-bottom: 1px solid thin #fff;
}
.orange {
  background-color: orange;
}
.v-application .uno >>> tbody tr {
  height: 76px !important;
}
.red {
  background-color: red;
}
.dos >>> tbody tr {
  height: 76px !important;
}
.blue {
  background-color: blue;
}
.black {
  background-color: black;
}
.purple {
  background-color: purple;
}
.grey {
  background-color: grey;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import moment from "moment";
import EditarTurno from "../Turnos/EditarTurno.vue";
import HoraFinExcepcion from "../Turnos/HoraFinExcepcion.vue";
import NuevoTurno from "../Turnos/NuevoTurno.vue";
import Colores from "../Turnos/Colores.vue";
import NuevoCobro from "../Caja/NuevoCobro.vue";
import VerCronogramas from "../Turnos/VerCronogramasActivos.vue";
import axios from "../../axios-auth.js";
import router from "../../router/index.js";

export default {
  name: "GestionTurnos",

  data: (vm) => ({
    clase: "",
    utilizaLlamador: false,
    roles: [],
    dialog: false,
    alert: false,
    showNuevoTurno: false,
    showEditarTurno: false,
    showColores: false,
    showMenu: false,
    showHoraFinExcepcion: false,
    x: 0,
    y: 0,
    itemsMenu: [],
    showCronogramas: false,
    showNuevoCobro: false,
    valid: true,
    copiar: 0,
    name: "",
    refrescarPantalla: null,
    fechaMaxima: null,
    interval: null,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    isLoading: false,
    expanded: [],
    singleExpand: false,
    search: "",
    headerLunes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerMartes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerMiercoles: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerJueves: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerViernes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerSabado: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerDomingo: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headersLength: {
      type: Number,
    },
    editedIndex: -1,
    editedItem: {
      hora: "",
      paciente: "",
    },
    defaultItem: {
      hora: "",
      paciente: "",
    },
    //date: new Date().toISOString().substr(0, 10),
    //dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: vm.toISOLocal(new Date()),
    dateFormatted: vm.formatDate(vm.toISOLocal(new Date())),
    menu: false,
    modal: false,
    menu2: false,
    horaItem: null,
    prof: "",
    profs: [],
    servicio: null,
    servicios: [],
    diaSemana: null,
    on: null,
    horaTurno: "",
    entreTurno: "",
    listaTurnosLunes: [],
    listaTurnosMartes: [],
    listaTurnosMiercoles: [],
    listaTurnosJueves: [],
    listaTurnosViernes: [],
    listaTurnosSabado: [],
    listaTurnosDomingo: [],
    itemCopia: {
      idTurno: null,
      tiempoTurno: null,
      cantIntervalos: null,
    },
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    refrescarPantalla() {
      this.refrescar();
    },
    prof: {
      handler(val) {
        if (val) {
          this.armarTurnos();
        }
      },
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Turno" : "Editar Turno";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    var hoy = new Date().getDay();
    if (hoy == 0) hoy = 6;
    else hoy -= 1;
    let dias = 1000 * 60 * 60 * 24 * hoy;

    let resta = new Date().getTime() - dias;
    this.date = this.toISOLocal(new Date(resta));
    this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));

    this.servicios = [...this.$store.state.usuario.profesionales];
    this.servicios.unshift({
      nomServicio: "TODOS",
      servicio: -1,
    });
    this.servicio = this.servicios[0].servicio;

    this.profs = this.$store.state.usuario.profesionales;
    if (this.$store.state.indexProf != 0)
      this.prof = this.$store.state.indexProf;
    else this.prof = this.profs[0].idProfesional;

    this.armarTurnos();
    this.utilizaLlamador =
      this.$store.state.usuario.institucion.utilizaLlamador;
    this.roles = this.$store.state.usuario.roles;

    let lunes = this.toISOLocal(new Date(resta));
    let dias1 = 1000 * 60 * 60 * 24 * 212;
    let resta1 = new Date(lunes).getTime() + dias1;
    this.fechaMaxima = this.toISOLocal(new Date(resta1));
  },
  mounted() {
    this.refrescarPantalla =
      this.$store.state.usuario.institucion.idInstitucion === 195
        ? true
        : false;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    buscarProfesionales() {
      if (this.servicio === -1) {
        this.profs = [...this.$store.state.usuario.profesionales];
      } else {
        this.profs = this.$store.state.usuario.profesionales.filter(
          (prof) => prof.servicio === this.servicio
        );
      }
      this.prof = this.profs[0].idProfesional;
    },
    async buscarPrimerTurno() {
      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias = 1000 * 60 * 60 * 24 * hoy;

      let resta = new Date().getTime() - dias;
      const date = this.toISOLocal(new Date(resta));

      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));
      this.ejecutarBusquedaPrimerTurno(date);
    },
    ejecutarBusquedaPrimerTurno(date) {
      const turnosData = {
        idProfesional: this.prof,
        fecha: date,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      //this.listaTurnos = []
      let self = this;
      this.$loading(true);
      axios
        .get("/Turnos/BuscarSemanaTurnoLibre?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (
            response.data != null &&
            response.data != "" &&
            response.data.length
          ) {
            self.dateFormatted = self.formatDate(
              self.toISOLocal(new Date(date))
            );
            self.date = date;
            self.armarTurnos();
            self.$loading(false);
          } else {
            let dias = 1000 * 60 * 60 * 24 * 8;
            let resta = new Date(date).getTime() + dias;
            let dateNew = self.toISOLocal(new Date(resta));
            if (moment(dateNew).isSameOrBefore(self.fechaMaxima)) {
              self.ejecutarBusquedaPrimerTurno(dateNew);
            } else {
              self.showAlert({
                icon: "success",
                title: "Profesional sin turnos en los próximos 6 meses",
                vm: self,
              });
              self.$loading(false);
            }
          }
        })
        .catch(function (error) {
          self.$loading(false);
        });
    },
    enviarDatosTurno(item) {
      const datosTurno = {
        idTurno: item.idTurno,
        fechaTurno: item.fechaTurnoString,
        horaTurno: item.hora,
      };
      let self = this;
      axios
        .get("/Turnos/EnviarDatosTurno", {
          params: {
            idTurno: datosTurno.idTurno,
            fechaTurno: datosTurno.fechaTurno,
            horaTurno: datosTurno.horaTurno,
          },
        })
        .then((response) => {
          self.showAlert({
            icon: "success",
            title: "Datos enviados exitosamente",
            vm: self,
          });
        })
        .catch((error) => {});
    },
    chequearEnviarDatosTurno(item) {
      if (
        (this.$store.state.usuario.institucion.idInstitucion === 114 ||
          this.$store.state.usuario.institucion.idInstitucion === 270) &&
        item.celular
      ) {
        return true;
      }
      return item.celular && item.celular.toString().length == 10;
    },
    setClasses(item) {
      return "row-pointer";
    },
    salir() {
      router.push("/Home");
    },
    llamarPaciente(item) {
      const datosLlamada = {
        nombreProfesional:
          this.$refs.vselect.selectedItems[0].nombreProfesional,
        idProfesional: this.prof,
        nombrePaciente: item.paciente,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        fechaTurno: item.fechaTurnoString,
        horaTurno: item.hora.toString().substr(0, 5),
      };
      let self = this;
      axios
        .get("/Turnos/LlamarPaciente?", {
          params: {
            nombreProfesional: datosLlamada.nombreProfesional,
            idProfesional: datosLlamada.idProfesional,
            nombrePaciente: datosLlamada.nombrePaciente,
            idInstitucion: datosLlamada.idInstitucion,
            fechaTurno: datosLlamada.fechaTurno,
            horaTurno: datosLlamada.horaTurno,
          },
        })
        .then((response) => {
          if (response.data == "Ok") {
            self.showAlert({
              icon: "success",
              title:
                "El paciente " + item.paciente + " fue llamado correctamente",
              vm: this,
            });
          }
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El paciente no pudo ser llamado",
            vm: this,
          });
        });
    },
    retrocederSemana() {
      let dias = 1000 * 60 * 60 * 24 * 6;
      let resta = new Date(this.date).getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));
      this.armarTurnos();
    },
    avanzarSemana() {
      let dias = 1000 * 60 * 60 * 24 * 8;
      let resta = new Date(this.date).getTime() + dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));
      this.armarTurnos();
    },
    definirAccion(item) {
      if (item.title === "Nuevo Turno") this.nuevoTurno(this.editedItem, 0);
      if (item.title === "Cancelar Turno") this.cancelarTurno(this.editedItem);
      if (item.title === "Revertir Cancelación")
        this.revertirCancelacion(this.editedItem);
      if (item.title === "EntreTurno") this.nuevoTurno(this.editedItem, 1);
      if (item.title === "Eliminar Turno") this.deleteItem(this.editedItem);
      if (item.title === "Eliminar Masivamente")
        this.deleteAllItems(this.editedItem);
      if (item.title === "Editar Turno") this.editarTurno(this.editedItem);
      if (item.title === "Copiar / Reprogramar")
        this.copiarReprogramar(this.editedItem);
      if (item.title === "Copiar") this.copiarTurno(this.editedItem);
      if (item.title === "Reprogramar") this.reprogramarTurno(this.editedItem);
      if (item.title === "Cobrar") this.cobrar(this.editedItem);
      if (item.title === "Historia Clínica")
        this.historiaClinica(this.editedItem);
      if (item.title === "Llamar Paciente")
        this.llamarPaciente(this.editedItem);
      if (item.title === "Iniciar Videoconsulta")
        this.iniciarVideoConsulta(this.editedItem);
      if (item.title === "Enviar Datos Turno")
        this.enviarDatosTurno(this.editedItem);
    },
    rowClasses(item) {
      //Here you can put your condition based on your logic...
      if (
        item.paciente === "Feriado" ||
        item.observacionesTurno === "Cancelación"
      ) {
        return "red";
      } else {
        if (item.idTurno > 0) {
          if (item.estadoTurno === 1) {
            if (
              item.idObraSocial == 162 ||
              item.idObraSocial == 91 ||
              item.idObraSocial == 696
            ) {
              switch (item.cantIntervalos) {
                case 1:
                  return "black";
                case 2:
                  return "black dos";
                case 3:
                  return "black tres";
                case 4:
                  return "black cuatro";
                case 5:
                  return "black cinco";
                case 6:
                  return "black seis";
                case 7:
                  return "black siete";
                case 8:
                  return "black ocho";
              }
            } else {
              if (item.entreTurno === 1) {
                return "grey";
              } else {
                switch (item.cantIntervalos) {
                  case 1:
                    return "blue";
                  case 2:
                    return "blue dos";
                  case 3:
                    return "blue tres";
                  case 4:
                    return "blue cuatro";
                  case 5:
                    return "blue cinco";
                  case 6:
                    return "blue seis";
                  case 7:
                    return "blue siete";
                  case 8:
                    return "blue ocho";
                }
              }
            }
          } else {
            if (item.estadoTurno === 2) {
              if (item.entreTurno === 1) {
                return "orange";
              } else {
                switch (item.cantIntervalos) {
                  case 1:
                    return "orange";
                  case 2:
                    return "orange dos";
                  case 3:
                    return "orange tres";
                  case 4:
                    return "orange cuatro";
                  case 5:
                    return "orange cinco";
                  case 6:
                    return "orange seis";
                  case 7:
                    return "orange siete";
                  case 8:
                    return "orange ocho";
                }
              }
            } else {
              if (item.entreTurno === 1) {
                return "purple";
              } else {
                switch (item.cantIntervalos) {
                  case 1:
                    return "purple";
                  case 2:
                    return "purple dos";
                  case 3:
                    return "purple tres";
                  case 4:
                    return "purple cuatro";
                  case 5:
                    return "purple cinco";
                  case 6:
                    return "purple seis";
                  case 7:
                    return "purple siete";
                  case 8:
                    return "purple ocho";
                }
              }
            }
          }
        } else {
          return "green";
        }
      }

      this.clase = "row-pointer";
    },
    revertirCancelacion(item) {
      this.$swal({
        title: "Revertir Cancelación",
        text: "¿Desea revertir la cancelación?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idExcepcion = item.idExcepcion;
          let self = this;
          axios
            .get("/Turnos/BorrarExcepcion?", {
              params: {
                idExcepcion: idExcepcion,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Cancelación revertida",
                vm: self,
              });
              setTimeout(self.armarTurnos(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La cancelación no pudo ser revertida.",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    despliegaMenu(item, row) {
      this.itemsMenu = [];
      if (
        item.paciente != "Feriado" &&
        item.observacionesTurno != "Cancelación"
      ) {
        if (this.copiar == 0) {
          if (item.idTurno == 0) {
            this.itemsMenu.push({
              title: "Nuevo Turno",
              icon: "mdi-calendar",
            });
            this.itemsMenu.push({
              title: "Cancelar Turno",
              icon: "mdi-block-helper",
            });
          } else {
            this.itemsMenu.push(
              { title: "Editar Turno", icon: "mdi-pencil" },
              { title: "EntreTurno", icon: "mdi-calendar-multiple" }
            );
            if (
              this.$store.state.usuario.institucion.idInstitucion !== 195 ||
              this.roles.some((o) => o.idRol === 1)
            ) {
              this.itemsMenu.push(
                { title: "Eliminar Turno", icon: "mdi-delete" },
                { title: "Eliminar Masivamente", icon: "mdi-delete-sweep" }
              );
            }
            if (
              this.roles.some((o) => o.idRol === 1) ||
              this.roles.some((o) => o.idRol === 4)
            )
              this.itemsMenu.push({
                title: "Historia Clínica",
                icon: "mdi-heart-pulse",
              });
            if (
              this.roles.some((o) => o.idRol === 1) ||
              this.roles.some((o) => o.idRol === 5)
            )
              this.itemsMenu.push({
                title: "Cobrar",
                icon: "mdi-currency-usd",
              });

            this.itemsMenu.push({
              title: "Copiar / Reprogramar",
              icon: "mdi-content-duplicate",
            });

            if (
              item.esVideoLlamada == false &&
              ((this.$store.state.usuario.institucion.idInstitucion === 195 &&
                (item.estadoTurno == 2 || item.estadoTurno == 3)) ||
                (this.$store.state.usuario.institucion.idInstitucion !== 195 &&
                  item.estadoTurno == 2)) &&
              this.utilizaLlamador == true &&
              (this.roles.some((o) => o.idRol === 1) ||
                this.roles.some((o) => o.idRol === 4)) &&
              this.esDeHoy(item) == true
            )
              this.itemsMenu.push({
                title: "Llamar Paciente",
                icon: "mdi-bullhorn",
              });
            if (
              item.esVideoLlamada == true &&
              (this.roles.some((o) => o.idRol === 1) ||
                this.roles.some((o) => o.idRol === 4)) &&
              this.esDeHoy(item) == true
            )
              this.itemsMenu.push({
                title: "Iniciar Videoconsulta",
                icon: "mdi-video",
              });
            if (
              item.celular &&
              item.celular.length == 10 &&
              this.$store.state.usuario.institucion.idInstitucion !== 114 &&
              this.$store.state.usuario.institucion.idInstitucion !== 270
            )
              this.itemsMenu.push({
                title: "Enviar Datos Turno",
                icon: "mdi-whatsapp",
              });
            if (
              item.celular &&
              (this.$store.state.usuario.institucion.idInstitucion === 114 ||
                this.$store.state.usuario.institucion.idInstitucion === 270)
            )
              this.itemsMenu.push({
                title: "Enviar Datos Turno",
                icon: "mdi-whatsapp",
              });
          }
        } else {
          this.itemsMenu.push(
            { title: "Copiar", icon: "mdi-content-duplicate" },
            { title: "Reprogramar", icon: "mdi-content-duplicate" }
          );
        }
      } else {
        if (item.observacionesTurno == "Cancelación") {
          this.itemsMenu.push({
            title: "Revertir Cancelación",
            icon: "mdi-thumb-up",
          });
        }
      }
      this.x = event.clientX;
      this.y = event.clientY;
      this.editedItem = item;
      this.showMenu = !this.showMenu;
    },
    cancelarTurno(item) {
      let self = this;
      const datosExcepcion = {
        idProfesional: this.prof,
        nombreProfesional:
          this.$refs.vselect.selectedItems[0].nombreProfesional,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        fechaDesde: item.fechaTurno,
        fechaDesdeString: item.fechaTurnoString,
        fechaHasta: item.fechaTurno,
        fechaHastaString: item.fechaTurnoString,
        observaciones: "Turno Cancelado",
        horaDesde: item.hora,
        horaHasta: "",
      };
      axios
        .get("/Turnos/ChequearUnicidadExcepcion?", {
          params: {
            idInstitucion: datosExcepcion.idInstitucion,
            fechaDesde: datosExcepcion.fechaDesdeString,
            fechaHasta: datosExcepcion.fechaHastaString,
            idProfesional: datosExcepcion.idProfesional,
          },
        })
        .then((response) => {
          if (response.data != "") {
            self.showAlert({
              icon: "error",
              title:
                "Ya existe una cancelación de turnero registrada en esa fecha para el profesional",
              vm: this,
            });
          } else {
            this.horaItem = item;
            this.showHoraFinExcepcion = true;
          }
        });
    },
    actualizarHoraHasta(val) {
      let self = this;

      const horaDesde = val.item.hora;
      const horaHasta = val.horaHasta;

      const datosExcepcion = {
        idProfesional: this.prof,
        nombreProfesional:
          this.$refs.vselect.selectedItems[0].nombreProfesional,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        fecha: val.item.fechaTurno,
        fechaString: val.item.fechaTurnoString,
        fechaHasta: val.item.fechaTurno,
        fechaHastaString: val.item.fechaTurnoString,
        observaciones: "Turno Cancelado",
        horaDesde: horaDesde,
        horaHasta: horaHasta,
        horaInicioCompleta: horaDesde.replace(":", "").trim().replace(" ", ""),
        horaFinCompleta: horaHasta.replace(":", "").trim().replace(" ", ""),
      };
      if (datosExcepcion.horaInicioCompleta > datosExcepcion.horaFinCompleta) {
        this.showAlert({
          icon: "error",
          title: "La hora desde no puede ser mayor que la hora hasta",
          vm: this,
        });
        return;
      }

      axios
        .post("/Turnos/GuardarExcepcion", datosExcepcion)
        .then((response) => {
          self.showHoraFinExcepcion = false;
          setTimeout(self.armarTurnos(), 1700);
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El turno no pudo ser cancelado.",
            vm: self,
          });
        });
    },
    historiaClinica(item) {
      this.$store.dispatch("setDni", item.dni);
      this.$store.dispatch("setRutaSalidaHC", 3);
      this.$store.dispatch("setIndexProf", this.prof);
      if (
        ((this.$store.state.usuario.institucion.idInstitucion !== 195 &&
          item.estadoTurno != 3) ||
          item.estadoTurno == 2) &&
        !this.$store.state.usuario.institucion.esEmpresa
      ) {
        this.$swal({
          title: "Marcar como Atendido",
          text: "¿Desea marcar el turno como atendido?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value == true) {
            axios.get("/Turnos/Atendido?", {
              params: {
                idTurno: item.idTurno,
              },
            });
          }
        });
      }
      router.push("/HistoriasClinicas");
    },
    iniciarVideoConsulta(item) {
      this.$store.dispatch("setVideollamada", true);
      this.$store.dispatch("setLinkVideollamada", item.linkVideoLlamada);
      this.$store.dispatch("setNombrePaciente", item.paciente);
      this.$store.dispatch(
        "setNombreProfesional",
        this.$refs.vselect.selectedItems[0].nombreProfesional
      );
      this.$store.dispatch("setDni", item.dni);
      this.$store.dispatch("setRutaSalidaHC", 3);
      this.$store.dispatch("setIndexProf", this.prof);
      if (
        ((this.$store.state.usuario.institucion.idInstitucion !== 195 &&
          item.estadoTurno != 3) ||
          item.estadoTurno == 2) &&
        !this.$store.state.usuario.institucion.esEmpresa
      ) {
        this.$swal({
          title: "Marcar como Atendido",
          text: "¿Desea marcar el turno como atendido?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value == true) {
            axios.get("/Turnos/Atendido?", {
              params: {
                idTurno: item.idTurno,
              },
            });
          }
        });
      }
      if (this.isMobile() == false) {
        router.push("/HistoriasClinicas");
      } else router.push("/VideoLlamada");
    },
    cobrar(item) {
      //this.editedItem = Object.assign({}, item);
      this.showNuevoCobro = true;
    },
    allowedDates: (val) => new Date(val).getDay() === 0,
    cierraNuevoCobro() {
      this.showNuevoCobro = false;
    },
    allowedDates: (val) => new Date(val).getDay() === 0,
    refrescar() {
      if (this.refrescarPantalla === true) {
        this.interval = setInterval(
          () => this.armarTurnos(),
          this.$store.state.usuario.institucion.tiempoRefresco * 1000
        );
      } else {
        clearInterval(this.interval);
      }
    },
    copiarReprogramar(item) {
      this.copiar = 1;
      this.alert = !this.alert;
      this.itemCopia.tiempoTurno = item.tiempoTurno;
      this.itemCopia.cantIntervalos = item.cantIntervalos;
      this.itemCopia.idTurno = item.idTurno;
    },
    cancelaCopia() {
      this.alert = !this.alert;
      this.copiar = 0;
      this.itemCopia.tiempoTurno = null;
      this.itemCopia.cantIntervalos = null;
      this.itemCopia.idTurno = null;
    },
    armarHeaders() {
      let dias = 1000 * 60 * 60 * 24;

      let lunes = new Date(this.date).getTime() + dias * 1;
      this.dateFormattedLunes = this.formatDate(
        this.toISOLocal(new Date(lunes))
      );

      let martes = new Date(this.date).getTime() + dias * 2;
      this.dateFormattedMartes = this.formatDate(
        this.toISOLocal(new Date(martes))
      );

      let miercoles = new Date(this.date).getTime() + dias * 3;
      this.dateFormattedMiercoles = this.formatDate(
        this.toISOLocal(new Date(miercoles))
      );

      let jueves = new Date(this.date).getTime() + dias * 4;
      this.dateFormattedJueves = this.formatDate(
        this.toISOLocal(new Date(jueves))
      );

      let viernes = new Date(this.date).getTime() + dias * 5;
      this.dateFormattedViernes = this.formatDate(
        this.toISOLocal(new Date(viernes))
      );

      let sabado = new Date(this.date).getTime() + dias * 6;
      this.dateFormattedSabado = this.formatDate(
        this.toISOLocal(new Date(sabado))
      );
      let domingo = new Date(this.date).getTime() + dias * 7;
      this.dateFormattedDomingo = this.formatDate(
        this.toISOLocal(new Date(domingo))
      );

      this.headerLunes = [];
      this.headerLunes.push({
        text: "Lunes " + this.dateFormattedLunes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerMartes = [];
      this.headerMartes.push({
        text: "Martes " + this.dateFormattedMartes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerMiercoles = [];
      this.headerMiercoles.push({
        text: "Miércoles " + this.dateFormattedMiercoles,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerJueves = [];
      this.headerJueves.push({
        text: "Jueves " + this.dateFormattedJueves,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerViernes = [];
      this.headerViernes.push({
        text: "Viernes " + this.dateFormattedViernes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerSabado = [];
      this.headerSabado.push({
        text: "Sábado " + this.dateFormattedSabado,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerDomingo = [];
      this.headerDomingo.push({
        text: "Domingo " + this.dateFormattedDomingo,
        align: "center",
        filterable: false,
        value: "hora",
      });
    },
    esDeHoy(item) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        this.toISOLocal(new Date()).substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);

      if (desde.toString() == hoy.toString()) {
        return true;
      } else {
        return false;
      }
    },
    armarTurnos() {
      this.isLoading = true;
      this.$loading(true);
      const turnosData = {
        idProfesional: this.prof,
        fecha: this.date,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      //this.listaTurnos = []
      let self = this;
      axios
        .get("/Turnos/ArmarTurnosWide?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
          },
        })
        .then(function (response) {
          self.listaTurnosLunes = response.data[0];
          self.listaTurnosMartes = response.data[1];
          self.listaTurnosMiercoles = response.data[2];
          self.listaTurnosJueves = response.data[3];
          self.listaTurnosViernes = response.data[4];
          self.listaTurnosSabado = response.data[5];
          self.listaTurnosDomingo = response.data[6];
          self.isLoading = false;
          self.$loading(false);
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$loading(false);
        });

      this.armarHeaders();
      // this.$store.dispatch("armarTurnos", {
      //   idProfesional: turnosData.idProfesional,
      //   fecha: turnosData.fecha
      // });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    editarTurno(item) {
      //this.editedIndex = this.listaTurnos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarTurno = true;
    },
    nuevoTurno(item, val) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        this.toISOLocal(new Date()).substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);
      if (desde < hoy) {
        this.$swal({
          title: "Fecha menor al día de hoy",
          text: "Cuidado, la fecha del turno es menor a la fecha actual. ¿Desea continuar igual?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.dismiss == "cancel") {
            return;
          } else {
            this.continuarNuevoTurno(item, val);
          }
        });
      } else {
        this.continuarNuevoTurno(item, val);
      }
    },
    continuarNuevoTurno(item, val) {
      if (this.copiar == 0) {
        //this.editedIndex = this.listaTurnos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        if (val === 0) {
          this.horaTurno = this.editedItem.hora;
          this.entreTurno = 0;
          this.showNuevoTurno = true;
          console.log(item.tipoTurno);
        } else {
          let self = this;
          axios
            .get("/Turnos/ChequearCantMaxEt?", {
              params: {
                fecha: self.editedItem.fechaTurnoString,
                idProfesional: self.prof,
              },
            })
            .then((response) => {
              if (response.data == "ok") {
                this.horaTurno = this.calcularHora(
                  this.editedItem.hora.substring(0, 5)
                );
                this.entreTurno = 1;
                this.showNuevoTurno = true;
              } else {
                self.showAlert({
                  icon: "error",
                  title:
                    "Supera la cantidad máxima de entre turnos diarios establecida para el profesional",
                  vm: self,
                });
              }
            })
            .catch((error) => {});
        }
      }
    },
    isMobile() {
      if (window.innerWidth < 992) {
        return true;
      } else {
        return false;
      }
    },
    copiarTurno(item) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        new Date().toISOString().substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);
      if (desde < hoy) {
        this.$swal({
          title: "Fecha menor al día de hoy",
          text: "Cuidado, la fecha elegida es menor a la fecha actual. ¿Desea continuar igual?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.dismiss == "cancel") {
            return;
          } else {
            this.continuarCopiaTurno(item);
          }
        });
      } else {
        this.continuarCopiaTurno(item);
      }
    },
    continuarCopiaTurno(item) {
      let self = this;
      this.$loading(true);

      const horaTurno = item.idTurno
        ? this.calcularHora(item.hora.substring(0, 5))
        : item.hora;
      const entreTurno = item.idTurno ? 1 : 0;
      const cantIntervalos = item.idTurno ? 1 : this.itemCopia.cantIntervalos;

      const datosTurno = {
        idTurno: this.itemCopia.idTurno,
        idProfesional: this.prof,
        horaTurno: horaTurno,
        entreTurno: entreTurno,
        diaTurno: item.fechaTurnoString,
        cantIntervalos: cantIntervalos,
        tiempoTurno: this.itemCopia.tiempoTurno,
        usuarioAlta: this.$store.state.usuario.usuario,
      };
      axios
        .get("/Turnos/CopiarTurno", {
          params: {
            idTurno: datosTurno.idTurno,
            idProfesional: datosTurno.idProfesional,
            horaTurno: datosTurno.horaTurno,
            entreTurno: datosTurno.entreTurno,
            fechaTurno: datosTurno.diaTurno,
            cantIntervalos: datosTurno.cantIntervalos,
            tiempoTurno: datosTurno.tiempoTurno,
            usuarioAlta: datosTurno.usuarioAlta,
          },
        })
        .then((response) => {
          if (response.data == "Turnos Existentes") {
            self.showAlert({
              icon: "error",
              title:
                "Existen turnos ya reservados dentro de la duración del turno que quiere reservar",
              vm: self,
            });
          } else {
            self.showAlert({
              icon: "success",
              title: "Turno copiado exitosamente",
              vm: self,
            });
            self.armarTurnos();
            self.cancelaCopia();
          }
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.showAlert({
            icon: "error",
            title: "El turno no pudo ser copiado",
            vm: self,
          });
        });
    },
    reprogramarTurno(item) {
      var desdeParte = item.fechaTurnoString.split("/");
      var desde = new Date(desdeParte[2], desdeParte[1] - 1, desdeParte[0]);
      var hoyParte = this.formatDate(
        new Date().toISOString().substr(0, 10)
      ).split("/");
      var hoy = new Date(hoyParte[2], hoyParte[1] - 1, hoyParte[0]);
      if (desde < hoy) {
        this.$swal({
          title: "Fecha menor al día de hoy",
          text: "Cuidado, la fecha elegida es menor a la fecha actual. ¿Desea continuar igual?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.dismiss == "cancel") {
            return;
          } else {
            this.continuarReprogramaTurno(item);
          }
        });
      } else {
        this.continuarReprogramaTurno(item);
      }
    },
    continuarReprogramaTurno(item) {
      let self = this;
      this.$loading(true);
      const horaTurno = item.idTurno
        ? this.calcularHora(item.hora.substring(0, 5))
        : item.hora;
      const entreTurno = item.idTurno ? 1 : 0;
      const cantIntervalos = item.idTurno ? 1 : this.itemCopia.cantIntervalos;

      const datosTurno = {
        idTurno: this.itemCopia.idTurno,
        idProfesional: this.prof,
        horaTurno: horaTurno,
        entreTurno: entreTurno,
        diaTurno: item.fechaTurnoString,
        cantIntervalos: cantIntervalos,
        tiempoTurno: this.itemCopia.tiempoTurno,
        usuarioModi: this.$store.state.usuario.usuario,
      };
      axios
        .get("/Turnos/ReprogramarTurno", {
          params: {
            idTurno: datosTurno.idTurno,
            idProfesional: datosTurno.idProfesional,
            horaTurno: datosTurno.horaTurno,
            entreTurno: datosTurno.entreTurno,
            fechaTurno: datosTurno.diaTurno,
            cantIntervalos: datosTurno.cantIntervalos,
            tiempoTurno: datosTurno.tiempoTurno,
            usuarioModi: datosTurno.usuarioModi,
          },
        })
        .then((response) => {
          if (response.data == "Turnos Existentes") {
            self.showAlert({
              icon: "error",
              title:
                "Existen turnos ya reservados dentro de la duración del turno que quiere reservar",
              vm: self,
            });
          } else {
            self.showAlert({
              icon: "success",
              title: "Turno reprogramado exitosamente",
              vm: self,
            });
            self.armarTurnos();
            self.cancelaCopia();
          }
          self.$loading(false);
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El turno no pudo ser reprogramado",
            vm: self,
          });
          self.$loading(false);
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    mostrarColores() {
      this.showColores = true;
    },
    mostrarCronogramas() {
      this.showCronogramas = true;
    },
    calcularHora(hora) {
      var division = hora.split(":");
      var hoy = new Date();
      var fecha = new Date(
        hoy.getYear(),
        hoy.getMonth(),
        hoy.getDay(),
        division[0],
        parseInt(division[1] - 1)
      );
      var minutos = fecha.getMinutes().toString();
      if (minutos.length == 1) minutos = "0" + minutos;
      var horaNueva = fecha.getHours() + ":" + minutos;
      return horaNueva;
    },
    deleteAllItems(item) {
      this.$swal({
        title: "Borrar Turnos Masivamente",
        text: "Atención! Esta acción borrará todos los turnos que el paciente posea con el profesional desde éste en adelante ¿Está seguro que desea continuar?",
        icon: "warning",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
            .get("/Turnos/BorrarTurnosMasivamente?", {
              params: {
                idProfesional: this.prof,
                fechaTurno: item.fechaTurno,
                idPaciente: item.idPaciente,
                usuario: this.$store.state.usuario.usuario,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Turnos borrados",
                vm: self,
              });
              setTimeout(self.armarTurnos(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "Los turnos no pudieron ser borrados",
                vm: this,
              });
            });
        } else {
        }
      });
    },
    deleteItem(item) {
      this.$swal({
        title: "Borrar Turno",
        text: "¿Desea borrar el turno?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTurno = item.idTurno;
          let self = this;
          axios
            .get("/Turnos/BorrarTurno?", {
              params: {
                idTurno: idTurno,
                usuario: this.$store.state.usuario.usuario,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Turno borrado",
                vm: self,
              });
              setTimeout(self.armarTurnos(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El turno no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    turnoRegistrado() {
      this.armarTurnos();
      setTimeout(this.cerrarVentana, 600);
    },
    cerrarVentana() {
      this.showNuevoTurno = false;
    },
    turnoModificado() {
      this.armarTurnos();
      setTimeout(this.cerrarVentanaModificar, 600);
    },
    cerrarVentanaModificar() {
      this.showEditarTurno = false;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
  components: {
    EditarTurno,
    NuevoTurno,
    Colores,
    VerCronogramas,
    NuevoCobro,
    HoraFinExcepcion,
  },
};
</script>
